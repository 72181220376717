body {
  height: auto;
}

.main {
  margin-top: 4em;
}

/* .masthead.segment {
	/* min-height: 400px;
	padding: 3em 0em;
	margin-top: -3em !important;
} */
/* .masthead .logo.item img {
	margin-right: 1em;
}
.masthead .ui.menu .ui.button {
	margin-left: 0.5em;
}
.masthead h1.ui.header {
	margin-top: 3em;
	margin-bottom: 0em;
	font-size: 3em;
	font-weight: normal;
}
.masthead h2 {
	font-size: 1.2em;
	font-weight: normal;
} */

.App-logo {
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search > .results {
  overflow-x: scroll;
  max-height: 500px;
}

.repertoireView,
.genreView {
  width: 100% !important;
}

.editSongButton,
.repertoireSongButtonGroup,
.genre {
  float: right;
}

@media only screen and (max-width: 767px) {
  tr {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .editSongButton,
  .repertoireSongButtonGroup,
  .genre {
    position: relative;
    top: -30px;
  }
  .repertoireView,
  .genreView {
    width: 100% !important;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

strong {
  font-size: large;
}

.footer {
  bottom: 0 !important;
  top: auto !important;
}
.container.main {
  padding-bottom: 50px;
}

.genre {
  font-size: 12px;
  padding: 3px;
  text-align: center;
  width: min-content;
  border-radius: 9px;
}

#goToTop {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: rgb(126, 40, 40); /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 7px 12px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
  z-index: 100000;
}

#goToTop:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}
